import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button } from "../components/Button";
import heavylifting from "../images/heavy-lifting.jpg";

function HeavyLiftingMoving() {
  return (
    <>
      <NavBar
        title="Heavy Lifting Moving | Point Blank Moving"
        description="Lee's Summit Movers, the best local moving company in Lee Summit. Point Blank Moving is Kansas City's premier moving company! Proud to be servicing Lee's Summit Missouri. The best locally operated moving company out of Lee Summit."
      />
      <div
        className="landing-container"
        style={{
          backgroundImage: `url(${heavylifting})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>
          Heavy Lifting Moving
        </h1>
        <div className="landing-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            path="/Quote"
          >
            Check Availability
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            path="/Contact"
          >
            GET IN CONTACT
          </Button>
        </div>
      </div>
      <div>
        <div className="content">
          <div className="content--loc--header">
            <h2
              style={{
                fontSize: "30px",
                marginTop: 20,
                marginBottom: 10,
                padding: 10,
                textAlign: "center",
              }}
            >
              Need Help Moving Heavy Furniture for Your Upcoming Move?{" "}
            </h2>
            <div>
              If you need heavy lifting moving services, make sure to hire a
              professional moving company and not your family or friends
              instead. There are many household items that are difficult to
              move, such as a safe or armoire, no matter the size or model. A
              typical home or office safe can weigh several hundred pounds, and
              the same for armoires.
            </div>
            <br />
            <div>
              The heavier an item weighs, the more it ensures it is extra secure
              (otherwise, that item could be easily stolen), however it also
              means more difficulties for your next move. To solve the heavy
              furniture lifting moving problem, many homeowners in the Kansas
              City area turn to professionals like Point Blank Moving to get the
              job done.
            </div>
          </div>
          <div className="content--loc--generic">
            <h3 style={{ fontSize: "25px", marginTop: 20, marginBottom: 10 }}>
              Why do homeowners hire professionals to do the heavy lifting when
              moving?
            </h3>
            <div>
              Even if you have a strong team of friends or family willing to
              help out, there are several good reasons to hire a professional
              moving company instead. For example:
              <ol style={{ marginLeft: 30, marginTop: 20 }}>
                <li style={{ marginTop: 15 }}>
                  <strong>Eliminate Risk of Major Injury</strong> - There is too
                  much risk for injury to yourself or those that are close to
                  you. If you aren’t properly trained, and hurt yourself, the
                  cost of the recovery could be significantly more than hiring a
                  professional from the beginning. Too much could go wrong
                  hauling hundreds or thousands of pounds of materials, and if
                  you are going up and down stairs further risk could be waiting
                  around the corner.
                </li>
                <li style={{ marginTop: 15 }}>
                  <strong>Higher risk for Furniture or Property Damage</strong>{" "}
                  - Mishandling heavy furniture can cause injury to people, as
                  well as it can also cause property damage to your home or
                  building. If the heavy piece of furniture is dropped or
                  smashed against the wall, your friends may escape injury, but
                  that area house likely won’t be so lucky. You can pay for the
                  repairs out of your pocket or be covered with the insurance of
                  a moving company should an accident happen (it is less likely
                  when done by professional movers).
                </li>
                <li style={{ marginTop: 15 }}>
                  <strong>Special moving equipment needed</strong> - If moving
                  the item(s) involves stairs, tight corners or a long walk to
                  the truck, special equipment is necessary to make it happen. A
                  heavy-duty appliance dolly, at the minimum, is a must. In most
                  cases, additional straps and ropes will be required to secure
                  the safe during movement. If there are a lot of stairs between
                  the safe and the truck, professionals may use a powered dolly
                  or lift to get it out. This greatly reduces the amount of
                  effort needed to do the job. Every now and then, a crane is
                  necessary to remove the safe, particularly when the safe is
                  installed well above the ground floor.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HeavyLiftingMoving;
